import { useAccountStore } from '@/stores/account';
import { deleteRequest, getRequest, postRequest } from '@/utils/httpClient';
import { CURRENT_USER_QUERY_STRING } from '@/gql/queries';
import { useRouter } from 'vue-router';
import { z } from 'zod';

const SessionSchema = z.object({
  username: z.string(),
  products: z.array(z.string()),
  scopes: z.array(z.string()),
  sessionId: z.string()
});

export function useAuth() {
  const accountStore = useAccountStore();
  const router = useRouter();

  const setSession = async (sessionData: any) => {
    try {
      const session = SessionSchema.parse(sessionData);
      accountStore.set('authenticated', Boolean(session.sessionId));
      accountStore.set('scopes', session.scopes);
      const userResponse = await getRequest('/user/users', {
        filter: { username: session.username },
        skipLinks: ''
      });
      accountStore.set('user', userResponse.data.data[0]);
    } catch (error) {
      console.error(error);
    }
  };

  async function checkCurrentSession() {
    try {
      const authResponse = await getRequest('/auth/sessions/current');
      await Promise.all([setSession(authResponse.data), getUserAccess()]);
    } catch {
      // ignore
      accountStore.reset();
    }
  }

  async function getUserAccess() {
    try {
      const userAccessResponse = await postRequest(
        import.meta.env.VITE_API_URL,
        { query: CURRENT_USER_QUERY_STRING },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );
      accountStore.set('me', userAccessResponse.data.data.me);
    } catch (error) {
      console.error(error);
    }
  }

  async function logout() {
    await deleteRequest('/auth/sessions/current');
    accountStore.reset();
    router.replace({ name: 'login' });
  }

  return {
    checkCurrentSession,
    getUserAccess,
    setSession,
    logout
  };
}
