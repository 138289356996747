<template>
  <v-snackbar timeout="-1" location="bottom right">
    New app version available

    <template #actions>
      <v-btn color="primary" variant="text" @click="emits('reload')"> Reload </v-btn>
      <v-btn color="white" variant="text" @click="emits('update:model-value', false)">Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts" setup>
const emits = defineEmits<{
  (e: 'update:model-value', value: boolean): void;
  (e: 'reload'): void;
}>();
</script>
