import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

export const useAppStore = defineStore('app', () => {
  const { locale: currentLocale } = useI18n();
  const themeStorage = useLocalStorage('theme', 'light');

  const locale = ref(currentLocale);
  const theme = ref(themeStorage.value);
  const error = ref<any>(null);

  function setLocale(newLocale: string) {
    locale.value = newLocale;
  }

  function setTheme(newTheme: string) {
    theme.value = newTheme;
    themeStorage.value = newTheme;
  }

  function setError(err: any) {
    error.value = err;
  }

  return {
    locale,
    setLocale,
    theme,
    setTheme,
    error,
    setError
  };
});
