export const settingsRouteNames = {
  general: 'settings-general',
  account: 'settings-account'
} as const;

const defaultPath = '/settings';

export default {
  path: defaultPath,
  name: 'settings',
  meta: {
    title: 'Settings'
  },
  component: () => import('@/views/settings/settings.vue'),
  children: [
    {
      path: 'general',
      name: settingsRouteNames.general,
      meta: {
        title: 'Settings general'
      },
      alias: defaultPath,
      component: () => import('@/views/settings/general/general.vue')
    },
    {
      path: 'account',
      name: settingsRouteNames.account,
      meta: {
        title: 'Settings account'
      },
      component: () => import('@/views/settings/account/account.vue')
    }
  ]
};
