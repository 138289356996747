<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="tw-grid tw-grid-cols-[auto,1fr] tw-gap-10 tw-gap-x-20">
    <span class="tw-text-base tw-inline-flex tw-items-center">
      <a :href="profileSetting.hash" role="link"> {{ profileSetting.meta.title }} </a>
    </span>
    <div class="tw-justify-self-start tw-flex tw-flex-col tw-items-center">
      <button @click="profileInput?.click()" type="button">
        <UserCircleIcon class="tw-size-40" />
        <v-file-input ref="profileInput" class="tw-hidden" />
      </button>
      <span class="tw-italic">Select avatar - JPG, PNG</span>
    </div>
    <!--  -->
    <span class="tw-text-base tw-inline-flex tw-items-center tw-gap-2">
      <a :href="languageSetting.hash" role="link"> {{ languageSetting.meta.title }} </a>
      <GlobeAltIcon class="tw-size-5" />
    </span>
    <LanguageSelector
      data-testid="languageSelector"
      :list-props="{ density: 'compact' }"
      class="tw-w-40"
      variant="outlined"
      hide-details
    />
    <!--  -->
    <span class="tw-text-base tw-inline-flex tw-items-center tw-gap-2">
      <a :href="themeSetting.hash" role="link"> {{ themeSetting.meta.title }} </a>
      <span v-if="!theme.current.value.dark" data-testid="lightThemeIcon">
        <SunIcon class="tw-size-5" />
      </span>
      <span v-else data-testid="darkThemeIcon">
        <MoonIcon class="tw-size-5" />
      </span>
    </span>
    <v-btn-toggle
      :modelValue="theme.global.name.value"
      @update:modelValue="changeTheme"
      variant="outlined"
      mandatory
    >
      <v-btn v-for="themeItem in themes" :key="themeItem.value" :value="themeItem.value">
        {{ themeItem.title }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { GlobeAltIcon, MoonIcon, SunIcon, UserCircleIcon } from '@heroicons/vue/24/outline';
import LanguageSelector from '@/components/Layout/components/LanguageSelector.vue';
import { useThemeSetting } from '@/composables/useThemeSetting';

const { themes, changeTheme, theme } = useThemeSetting();
const profileInput = ref<HTMLInputElement | null>(null);
</script>

<script lang="ts">
import { settingsRouteNames } from '@/views/settings/routes';

const profileSetting = {
  hash: '#profile',
  name: settingsRouteNames.general,
  meta: { title: 'Profile' }
};
const themeSetting = {
  hash: '#theme',
  name: settingsRouteNames.general,
  meta: { title: 'Theme' }
};
const languageSetting = {
  hash: '#language',
  name: settingsRouteNames.general,
  meta: { title: 'Language' }
};
export const generalSettingSearchRoutes = [profileSetting, themeSetting, languageSetting];
</script>
