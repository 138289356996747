import axios, { type AxiosRequestConfig } from 'axios';
import { LOGIN_API_URL, isDev } from '@/utils/env';
import { useAccountStore } from '@/stores/account';
import { useAppStore } from '@/stores/app';
import router from '@/router';
import * as Sentry from '@sentry/vue';

const instance = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/vnd.api+json'
  }
});

instance.interceptors.request.use(
  function (config) {
    if (config.url && !/^(http|https):\/\//.test(config.url)) {
      config.url = LOGIN_API_URL + config.url;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    if (!isDev) {
      if (![401, 403].includes(error.response?.status)) {
        Sentry.captureException(error);
      }
    }

    if (error.response?.status === 401) {
      const accountStore = useAccountStore();
      accountStore.reset();
      router.replace({ name: 'login', query: { redirect: router.currentRoute.value.fullPath } });
    } else if (error.response?.status !== 403) {
      const appStore = useAppStore();
      appStore.setError(error);
    }

    return Promise.reject(error);
  }
);

const getRequest = (
  url: string,
  params?: AxiosRequestConfig['params'],
  config?: AxiosRequestConfig
) => {
  return instance.get(url, Object.assign(config || {}, { params }));
};

const patchRequest = (url: string, data: any = {}, config?: AxiosRequestConfig) => {
  return instance.patch(url, data, config);
};

const postRequest = (url: string, data: any = {}, config?: AxiosRequestConfig) => {
  return instance.post(url, data, config);
};

const putRequest = (url: string, data: any = {}, config?: AxiosRequestConfig) => {
  return instance.put(url, data, config);
};

const deleteRequest = (url: string, config?: AxiosRequestConfig) => {
  return instance.delete(url, config);
};

export { getRequest, postRequest, patchRequest, deleteRequest, putRequest };
