import type { RouteLocation } from 'vue-router';

export default {
  path: '/orders/:orderId?',
  name: 'orders',
  meta: {
    title: 'Orders',
    stringGroups: ['DocumentList']
  },
  props: (route: RouteLocation) => ({
    orderId: route.params.orderId,
    orderType: 'ORDER'
  }),
  component: () => import('@/views/orders/orders.vue')
};
