import { createApp, provide, h } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import App from '@/App.vue';
import { registerPlugins } from '@/plugins';
import { registerComponents } from '@/plugins/component';
import { createApolloClient } from '@/plugins/apollo';
import '@/styles/main.css';

const app = createApp({
  setup() {
    provide(DefaultApolloClient, createApolloClient());
  },
  render: () => h(App)
});

import('@/plugins/sentry').then(({ initSentry }) => initSentry(app));

registerPlugins(app);
registerComponents(app);

app.mount('#app');
