import router from '@/router';
import { print } from 'graphql';
import { useAccountStore } from '@/stores/account';
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  Observable,
  split,
  type FetchResult,
  type Operation
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { createClient, type Client, type ClientOptions } from 'graphql-sse';
import { getMainDefinition } from '@apollo/client/utilities';

class SSELink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink)
        }
      );
    });
  }
}

const sseLink = new SSELink({
  url: import.meta.env.VITE_API_URL,
  credentials: 'include'
});

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_API_URL,
  credentials: 'include'
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  sseLink,
  httpLink
);

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    console.error(graphQLErrors);
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError.stack}`);
    const context = operation.getContext();
    if (context?.response?.status === 401) {
      const accountStore = useAccountStore();
      accountStore.reset();
      accountStore.set('sessionExpired', true);
      router.replace({
        name: 'login',
        query: { redirectTo: router.currentRoute.value.fullPath }
      });
    }
  }
});

export const createApolloClient = function () {
  return new ApolloClient({
    link: errorLink.concat(link),
    cache: new InMemoryCache()
  });
};
