<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-layout class="tw-min-h-screen">
    <v-app-bar scroll-behavior="elevate">
      <img class="tw-size-10 tw-rounded-full tw-mx-4" src="@/assets/icons/logo.svg" alt="Logo" />
      <template #append>
        <v-btn variant="text" rounded class="tw-ml-4">
          <span class="tw-mr-2"> {{ accountStore.displayName }} </span>
          <UserCircleIcon class="tw-size-6" />
          <v-menu activator="parent">
            <v-list class="tw-p-0">
              <v-list-item @click="logout" data-testid="logoutButton">
                <template #prepend>
                  <ArrowRightStartOnRectangleIcon class="tw-mr-4 tw-size-5" />
                </template>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn>
      </template>
    </v-app-bar>
    <div class="tw-grid tw-place-items-center tw-w-full">
      <div class="tw-text-center">
        <p class="tw-font-semibold">401</p>
        <h1 class="tw-mt-4 tw-text-3xl tw-font-bold tw-tracking-tight">Unauthorized</h1>
        <p class="tw-mt-4">Sorry, you are not allowed to access this page.</p>
      </div>
    </div>
  </v-layout>
</template>

<script lang="ts" setup>
import { useAuth } from '@/composables/useAuth';
import { useAccountStore } from '@/stores/account';
import { UserCircleIcon, ArrowRightStartOnRectangleIcon } from '@heroicons/vue/24/outline';

const accountStore = useAccountStore();
const { logout } = useAuth();
</script>
