import type { Maybe, UserAccess } from '@/gql/schema';
import { patchRequest } from '@/utils/httpClient';
import { serialise } from 'kitsu-core';
import { defineStore } from 'pinia';

type User = {
  id: string;
  attributes: {
    officeCountryCode: string;
    marketStandard: string;
    frontEndSettings: string;
    username: string;
    jobTitle: string;
    company: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    mobile: string;
    isSuperuser: boolean;
  };
  type: string;
};

type AccountState = {
  me: Maybe<{ access: UserAccess }>;
  user: Maybe<Partial<User>>;
  scopes: string[];
  authenticated: Maybe<Boolean>; // null = not yet checked, false = not authenticated, true = authenticated
  sessionExpired: boolean;
};

export const useAccountStore = defineStore('accountStore', {
  state: () => {
    const state: AccountState = {
      me: null as Maybe<{ access: UserAccess }>,
      user: null as Maybe<Partial<User>>,
      scopes: [],
      authenticated: null as Maybe<Boolean>, // null = not yet checked, false = not authenticated, true = authenticated
      sessionExpired: false
    };
    return state;
  },

  getters: {
    displayName: (state) => {
      if (!state.user) return '';
      return `${state.user.attributes?.firstName} ${state.user.attributes?.lastName}`;
    },
    frontEndSettings: (state): any => {
      let feSettings = {};
      try {
        if (state.user?.attributes?.frontEndSettings) {
          feSettings = JSON.parse(state.user.attributes.frontEndSettings);
        }
      } catch {
        /**/
      }

      return feSettings;
    },
    hasAccess: (state) => {
      return state.scopes.includes('dev_user') || state.user?.attributes?.isSuperuser;
    }
  },

  actions: {
    set<K extends keyof AccountState>(state: K, value: AccountState[K]) {
      this[state] = value as any;
    },
    reset() {
      this.user = null;
      this.me = null;
      this.authenticated = false;
      this.scopes = [];
    },
    saveFrontendSettings(settings: any) {
      if (!this.user?.attributes?.frontEndSettings) return;

      this.user.attributes.frontEndSettings = JSON.stringify(settings);

      const payload = serialise('user', {
        id: this.user.id,
        frontEndSettings: this.user.attributes.frontEndSettings
      });

      return patchRequest(`/user/users/${this.user.id}`, payload);
    }
  }
});
