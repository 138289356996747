<template>
  <v-select v-model="appStore.locale" :items="languages" variant="underlined" />
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { useAppStore } from '@/stores/app';

const appStore = useAppStore();
const languages = computed(() => [
  { value: 'no', title: 'Norsk' },
  { value: 'se', title: 'Svenska' },
  { value: 'dk', title: 'Danish' },
  { value: 'gb', title: 'English' },
  { value: 'ie', title: 'Irish' }
]);

onMounted(() => {
  if (appStore.locale) {
    return;
  }

  let language;
  if (navigator.language.slice(0, 2).match(/^(no|se|dk|gb|ie)$/)) {
    language = navigator.language.slice(0, 2);
  }
  if (!language) {
    language = 'gb';
  }

  appStore.setLocale(language);
});
</script>
