import { createI18n } from 'vue-i18n';
import axios from 'axios';
import { getRequest } from '@/utils/httpClient';
import { useStorage } from '@vueuse/core';
import moment from 'moment';
import 'moment/dist/locale/nb';
import 'moment/dist/locale/sv';
import 'moment/dist/locale/da';
import { nextTick } from 'vue';

export type SupportedLocale = (typeof supportedLocales)[number];
export const supportedLocales = ['no', 'se', 'dk', 'gb'] as const;

const languageToLocale: { [key: string]: string } = {
  no: 'nb',
  se: 'sv',
  dk: 'da',
  gb: 'en',
  ie: 'en'
};

const defaultLocale: SupportedLocale = 'gb';
const storageLocale = useStorage('locale', defaultLocale);
const locale = supportedLocales.includes(storageLocale.value as SupportedLocale)
  ? storageLocale.value
  : defaultLocale;

export const i18n = createI18n({
  legacy: false,
  locale,
  fallbackLocale: defaultLocale,
  messages: {}
});

export function setI18nLanguage(locale: string) {
  i18n.global.locale.value = locale;
  storageLocale.value = locale;
  axios.defaults.headers.common['Accept-Language'] = locale;
}

export async function loadLocaleMessages(locale: string, stringGroups: Set<string>) {
  let selectedLocale = locale;
  if (selectedLocale === 'ie') selectedLocale = 'gb';
  if (!selectedLocale) {
    selectedLocale = defaultLocale;
  }
  moment.locale(languageToLocale[selectedLocale]);
  await getRequest('/lang/strings', {
    lang: selectedLocale,
    groups: Array.from(stringGroups).join(',')
  }).then((res: { data: any }) => {
    i18n.global.mergeLocaleMessage(locale, res.data);
  });

  setI18nLanguage(locale);

  return nextTick();
}
