import { onMounted, onBeforeUnmount, ref, watchEffect } from 'vue';
import { useIntervalFn } from '@vueuse/core';
import { isDev } from '@/utils/env';

export function useCheckForUpdates(pollingInterval: number) {
  const hasNewVersion = ref(false);
  const { pause, resume, isActive } = useIntervalFn(check, pollingInterval, { immediate: false });

  function check() {
    if (isDev) return;

    fetch(import.meta.env.VITE_BASE_URL)
      .then((res) => res.text())
      .then((documentText) => {
        const group = /data-app-version="(\d+)"/g.exec(documentText);
        if (group) {
          const [, newVersion] = group;
          hasNewVersion.value = newVersion != __APP_VERSION__;
        }
      });
  }

  function onVisibilityChange() {
    if (document.visibilityState === 'visible') {
      check();
      resume();
    } else if (isActive.value) {
      pause();
    }
  }

  watchEffect(() => {
    if (hasNewVersion.value && isActive.value) {
      pause();
    } else {
      resume();
    }
  });

  onMounted(() => {
    if (isDev) return;

    resume();
    window.addEventListener('visibilitychange', onVisibilityChange);
  });

  onBeforeUnmount(() => {
    if (isDev) return;

    window.removeEventListener('visibilitychange', onVisibilityChange);
  });

  return { hasNewVersion };
}
