export const colors = {
  primary: '#FFDD00',
  grey: {
    200: '#F5F5F5',
    400: '#E7E7E7',
    500: '#484856',
    600: '#A1A1A1',
    800: '#262734',
    900: '#1F1F29',
    1000: '#1B1B20'
  },
  green: {
    100: '#BFFFB5',
    200: '#C6ECD3'
  },
  red: {
    100: '#F2D0D1',
    200: '#E77E7E'
  },
  yellow: {
    100: '#FFEE74'
  },
  blue: {
    100: '#C5D9EC'
  },
  orange: {
    100: '#FFEAB6'
  },
  workStationStatus: {
    'factory-close': '#FFFFFF',
    'available-capacity': '#C6ECD3',
    'loan-available': '#FFEAB6',
    'overflow-production': '#C5D9EC',
    'overflow-sale': '#F2D0D1'
  }
};

/** @type {import('tailwindcss').Config} */
export default {
  prefix: 'tw-',
  important: true,
  content: ['./index.html', './src/**/*.{js,ts,vue}'],
  theme: {
    extend: {
      colors,
      borderRadius: {
        DEFAULT: '10px',
        sm: '2px',
        4: '4px'
      },
      boxShadow: {
        DEFAULT: '0px 0px 20px #00000010',
        md: '0px 0px 50px #00000025'
      }
    }
  },
  plugins: []
};
