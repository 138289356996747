export default {
  path: '/login',
  name: 'login',
  meta: {
    title: 'Log In',
    layout: 'content',
    requiresAuth: false
  },
  component: () => import('@/views/login/LogIn.vue')
};
