import gql from 'graphql-tag';

export const CUSTOMER_FIELDS = gql`
  fragment CustomerFields on Customer {
    id
    name
    customerNumber
  }
`;

export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    firstName
    lastName
    username
  }
`;

export const ORDER_PRICE_FIELDS = gql`
  fragment OrderPriceFields on OrderPriceData {
    list
    model
  }
`;

export const ORDER_DOCUMENT_FIELDS = gql`
  fragment OrderDocumentFields on OrderDocument {
    id
    language
    extension
    description
    downloadUrl
    orderType
    version
    numBytes
    updatedAt
    updatedBy {
      firstName
      lastName
    }
    type
    printType
    frontPage {
      id
      customer {
        ...CustomerFields
      }
    }
  }
  ${CUSTOMER_FIELDS}
`;

export const ORDER_FIELDS = gql`
  fragment OrderFields on Order {
    id
    projectName
    remarks
    createdAt
    updatedAt
    orderNumber
    isFavorite
    orderType
    customer {
      ...CustomerFields
    }
    customerOwner {
      ...CustomerFields
    }
    salesPerson {
      ...UserFields
    }
    quoteCustomers {
      ...CustomerFields
    }
    price {
      ...OrderPriceFields
    }
  }
  ${ORDER_PRICE_FIELDS}
  ${CUSTOMER_FIELDS}
  ${USER_FIELDS}
`;
