export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  /**
   * The user's favorites. Returns null if the requesting user is not authorized to view this user's favorites. Users
   * are always allowed to view their own access.
   */
  favorites?: Maybe<Array<Favorite>>;
  /**
   * The user's access. Returns null if the requesting user is not authorized to view this user's access. Users are
   * always allowed to view their own access.
   */
  access?: Maybe<UserAccess>;
};

/** A connection to a list of items. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<UsersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export type Factory = {
  __typename?: 'Factory';
  id: Scalars['ID'];
  /**
   * A country code for the country where this factory is located. May be `null`, for example if this is not a real
   * factory.
   */
  country?: Maybe<Scalars['String']>;
};

export type SetFavoriteIndexPayload = {
  __typename?: 'SetFavoriteIndexPayload';
  favorite?: Maybe<Favorite>;
  errors?: Maybe<Array<SetFavoriteIndexError>>;
  query: Query;
};

/** An edge in a connection. */
export type CustomersEdge = {
  __typename?: 'CustomersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Customer>;
};

export type OrdersFiltersInput = {
  /** Only return orders with this order type. */
  orderType?: Maybe<OrderType>;
  /**
   * Return the orders most relevant to the current user. This is not intended to be used together with other
   * filters.
   */
  currentOrders?: Scalars['Boolean'];
  /** Whether to search for archived or non-archived orders. */
  isArchived?: Maybe<Scalars['Boolean']>;
  /** Only return orders matching this search term. */
  searchTerm?: Maybe<Scalars['String']>;
  /** Only return orders matching these search terms. */
  searchTerms?: Maybe<Array<Scalars['String']>>;
  /** Only return the order with this exact order number, if it exists. */
  orderNumber?: Maybe<Scalars['String']>;
  /** Only return orders with the specified isFavorite value. */
  isFavorite?: Maybe<Scalars['Boolean']>;
  /** Only return orders owned by customers with one of these IDs. */
  customerOwners?: Maybe<Array<Scalars['ID']>>;
  /** Only return orders for customers with one of these IDs. */
  customers?: Maybe<Array<Scalars['ID']>>;
  /** Only return orders where the sales person is a user with one of these IDs. */
  salesPersons?: Maybe<Array<Scalars['ID']>>;
};

export type WorkstationCapacity = {
  __typename?: 'WorkstationCapacity';
  /** The workstation ID. */
  id: Scalars['ID'];
  /** The workstation's capacity planning type. */
  planningType: CapacityPlanningType;
  /** A list of weekly capacity data for this workstation. */
  weeklyCapacities: Array<WorkstationWeeklyCapacity>;
  /** Aggregate order data for this workstation. */
  orderSummary?: Maybe<CapacityOrderSummary>;
};

export type WorkstationCapacityWeeklyCapacitiesArgs = {
  category: CapacityCategory;
  startYear: Scalars['Int'];
  startWeek: Scalars['Int'];
  numWeeks: Scalars['Int'];
};

export type WorkstationCapacityOrderSummaryArgs = {
  category: CapacityCategory;
  year: Scalars['Int'];
  week: Scalars['Int'];
};

export type UsersSortTermInput = {
  order?: SortOrder;
  nulls?: Maybe<NullOrder>;
  key: UsersSortKey;
};

export type TestTriggerOrderUpdatedInput = {
  orderId: Scalars['ID'];
};

/** Indicates that the user does not have access to the item with the specified ID. */
export type NoAccessToFavoriteItemError = Error & {
  __typename?: 'NoAccessToFavoriteItemError';
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type CustomersConnection = {
  __typename?: 'CustomersConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<CustomersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Customer>>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export type UsersFiltersInput = {
  /** Only return users matching this search term. */
  searchTerm?: Maybe<Scalars['String']>;
};

export type CapacityOrderSummaryOrdersFiltersInput = {
  /** Only return orders with this capacity order type. */
  capacityOrderType?: Maybe<CapacityOrderType>;
  /** Filters to apply from the top-level orders field. */
  ordersFilters?: Maybe<OrdersFiltersInput>;
};

/** Specifies where null values will appear in a sort operation. */
export enum NullOrder {
  /** All null values appear before all non-null values. */
  First = 'FIRST',
  /** All null values appear after all non-null values. */
  Last = 'LAST'
}

export type OrderPriceData = {
  __typename?: 'OrderPriceData';
  model: PriceModel;
  list: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  orderUpdated?: Maybe<OrderUpdatedPayload>;
  capacityUpdated?: Maybe<CapacityUpdatedPayload>;
};

export type SubscriptionOrderUpdatedArgs = {
  orderId: Scalars['ID'];
};

export type DeleteFavoritePayload = {
  __typename?: 'DeleteFavoritePayload';
  errors?: Maybe<Array<DeleteFavoriteError>>;
  query: Query;
};

export enum CapacityPlanningType {
  Production = 'PRODUCTION',
  Sales = 'SALES'
}

export enum OrderDocumentType {
  Print = 'PRINT',
  Production = 'PRODUCTION',
  Internal = 'INTERNAL',
  System = 'SYSTEM',
  Customer = 'CUSTOMER'
}

export type UserAccess = {
  __typename?: 'UserAccess';
  capacity: UserCapacityAccess;
  order: UserOrderAccess;
};

export type CustomersSortTermInput = {
  order?: SortOrder;
  nulls?: Maybe<NullOrder>;
  key: CustomersSortKey;
};

export enum WorkstationCapacityStatus {
  FactoryClosed = 'FACTORY_CLOSED',
  AvailableCapacity = 'AVAILABLE_CAPACITY',
  LoanAvailable = 'LOAN_AVAILABLE',
  OverflowProduction = 'OVERFLOW_PRODUCTION',
  OverflowSales = 'OVERFLOW_SALES'
}

export type UserCapacityAccess = {
  __typename?: 'UserCapacityAccess';
  /** Whether the user has access to the capacity subsystem at all. */
  hasAccess: Scalars['Boolean'];
  /** The codes for the factories for which the user can access capacity data. */
  factories: Array<Scalars['String']>;
  /** The categories for which the user can access capacity data. */
  categories: Array<CapacityCategory>;
  /** The workstation planning types for which the user can access capacity data. */
  planningTypes: Array<CapacityPlanningType>;
  /** The aggregate values for which the user can access capacity data. */
  aggregateValueTypes: Array<CapacityAggregateValueType>;
  /** Whether the user has access to view order summaries. */
  canViewOrderSummary: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  /** Returns users matching the specified arguments. */
  users?: Maybe<UsersConnection>;
  /** Returns the user with the specified ID. */
  user?: Maybe<User>;
  /** Returns the currently authenticated user. */
  me: User;
  order?: Maybe<Order>;
  /**
   * Returns orders matching the specified arguments. This requires the requesting user's
   * `access.order.hasReadAccess` to return `true` for the specified order type.
   */
  orders?: Maybe<OrdersConnection>;
  /** Returns customers matching the specified arguments. */
  customers?: Maybe<CustomersConnection>;
  customer?: Maybe<Customer>;
  /**
   * Capacity data for the specified factory. This requires the requesting user's `access.capacity.hasAccess` to be
   * true.
   */
  factoryCapacity?: Maybe<FactoryCapacity>;
};

export type QueryUsersArgs = {
  filters?: Maybe<UsersFiltersInput>;
  sort?: Maybe<Array<UsersSortTermInput>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryOrderArgs = {
  id: Scalars['ID'];
};

export type QueryOrdersArgs = {
  filters?: Maybe<OrdersFiltersInput>;
  sort?: Maybe<Array<OrdersSortTermInput>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};

export type QueryCustomersArgs = {
  filters?: Maybe<CustomersFiltersInput>;
  sort?: Maybe<Array<CustomersSortTermInput>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};

export type QueryCustomerArgs = {
  id: Scalars['ID'];
};

export type QueryFactoryCapacityArgs = {
  factory: Scalars['ID'];
};

/** Provides capacity data for a factory. */
export type FactoryCapacity = {
  __typename?: 'FactoryCapacity';
  /** The associated factory. */
  factory: Factory;
  /** The factory's workstations. */
  workstations: Array<WorkstationCapacity>;
  /** The workstation with the specified ID. */
  workstation?: Maybe<WorkstationCapacity>;
  /** A list of weekly capacity values for the factory. */
  weeklyValues: Array<FactoryWeeklyValue>;
  /** Aggregate order data for this factory. */
  orderSummary?: Maybe<CapacityOrderSummary>;
};

/** Provides capacity data for a factory. */
export type FactoryCapacityWorkstationsArgs = {
  planningTypes: Array<CapacityPlanningType>;
};

/** Provides capacity data for a factory. */
export type FactoryCapacityWorkstationArgs = {
  workstation: Scalars['ID'];
};

/** Provides capacity data for a factory. */
export type FactoryCapacityWeeklyValuesArgs = {
  valueType: CapacityAggregateValueType;
  planningTypes: Array<CapacityPlanningType>;
  category: CapacityCategory;
  startYear: Scalars['Int'];
  startWeek: Scalars['Int'];
  numWeeks: Scalars['Int'];
};

/** Provides capacity data for a factory. */
export type FactoryCapacityOrderSummaryArgs = {
  planningTypes: Array<CapacityPlanningType>;
  category: CapacityCategory;
  year: Scalars['Int'];
  week: Scalars['Int'];
};

export type OrderUpdatedPayload = {
  __typename?: 'OrderUpdatedPayload';
  order?: Maybe<Order>;
};

export enum OrdersSortKey {
  /**
   * Sort the results by relevance to the search term. This is used only if the `searchTerm` filter is provided.
   * Otherwise, it is ignored.
   */
  Relevance = 'RELEVANCE',
  OrderNumber = 'ORDER_NUMBER',
  UpdatedAt = 'UPDATED_AT',
  IsFavorite = 'IS_FAVORITE',
  CustomerOwnerNumber = 'CUSTOMER_OWNER_NUMBER',
  CustomerOwnerName = 'CUSTOMER_OWNER_NAME',
  CustomerNumber = 'CUSTOMER_NUMBER',
  CustomerName = 'CUSTOMER_NAME',
  SalesPersonFirstName = 'SALES_PERSON_FIRST_NAME',
  SalesPersonLastName = 'SALES_PERSON_LAST_NAME'
}

export type FavoriteItem = Order;

export type SetFavoriteIndexError = FavoriteNotFoundError;

export type CapacityUpdatedPointer = {
  __typename?: 'CapacityUpdatedPointer';
  workstation: Scalars['ID'];
  year: Scalars['Int'];
  week: Scalars['Int'];
};

export type DeleteFavoriteError = FavoriteNotFoundError;

/** An edge in a connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

/** Defines when a policy shall be executed. */
export enum ApplyPolicy {
  /** Before the resolver was executed. */
  BeforeResolver = 'BEFORE_RESOLVER',
  /** After the resolver was executed. */
  AfterResolver = 'AFTER_RESOLVER',
  /** The policy is applied in the validation step before the execution. */
  Validation = 'VALIDATION'
}

export type OrderDocumentOwner = Order | Customer;

export enum FavoriteItemType {
  Order = 'ORDER'
}

/** Provides aggregate order capacity data for a specific summary value type. */
export type CapacityOrderSummaryValues = {
  __typename?: 'CapacityOrderSummaryValues';
  /** The type of value represented by this object's numeric fields. */
  valueType: CapacityAggregateValueType;
  /** The aggregate value for incomplete orders. Returns `null` if there are no such orders in this summary. */
  incomplete?: Maybe<Scalars['Float']>;
  /** The aggregate value for control orders. Returns `null` if there are no such orders in this summary. */
  control?: Maybe<Scalars['Float']>;
  /** The aggregate value for final orders. Returns `null` if there are no such orders in this summary. */
  final?: Maybe<Scalars['Float']>;
  /** The aggregate value for all orders regardless of type. */
  total?: Maybe<Scalars['Float']>;
};

export enum CustomersSortKey {
  /**
   * Sort the results by relevance to the search term. This is used only if the `searchTerm` filter is provided.
   * Otherwise, it is ignored.
   */
  Relevance = 'RELEVANCE',
  CustomerNumber = 'CUSTOMER_NUMBER',
  Name = 'NAME'
}

/** Specifies a type of aggregate value used in capacity data. */
export enum CapacityAggregateValueType {
  /** The associated value is the number of orders. The value is guaranteed to not contain a non-zero fractional part. */
  OrderCount = 'ORDER_COUNT',
  /** The associated value is the cost price in NOK. */
  CostPrice = 'COST_PRICE',
  /** The associated value is the customer price in NOK. */
  CustomerPrice = 'CUSTOMER_PRICE',
  /** The associated value is the gross margin in percent. */
  GrossMargin = 'GROSS_MARGIN'
}

export enum UsersSortKey {
  /**
   * Sort the results by relevance to the search term. This is used only if the `searchTerm` filter is provided.
   * Otherwise, it is ignored.
   */
  Relevance = 'RELEVANCE',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Only used to simplify internal testing. Triggers the orderUpdated subscription for the specified order. */
  testTriggerOrderUpdated: TestTriggerOrderUpdatedPayload;
  /** Marks an item as a favorite for a user. The index will be clamped to the allowed range. */
  createFavorite: CreateFavoritePayload;
  /** Sets the index of a favorite. The index will be clamped to the allowed range. */
  setFavoriteIndex: SetFavoriteIndexPayload;
  /** Removes a favorite. */
  deleteFavorite: DeleteFavoritePayload;
  /** Only used to simplify internal testing. Triggers the capacityUpdated subscription with test data. */
  testTriggerCapacityUpdated: TestTriggerCapacityUpdatedPayload;
};

export type MutationTestTriggerOrderUpdatedArgs = {
  input: TestTriggerOrderUpdatedInput;
};

export type MutationCreateFavoriteArgs = {
  input: CreateFavoriteInput;
};

export type MutationSetFavoriteIndexArgs = {
  input: SetFavoriteIndexInput;
};

export type MutationDeleteFavoriteArgs = {
  input: DeleteFavoriteInput;
};

export type DeleteFavoriteInput = {
  favoriteId: Scalars['ID'];
};

/** Provides aggregate order capacity data for a factory or workstation. */
export type CapacityOrderSummary = {
  __typename?: 'CapacityOrderSummary';
  /** The factory this summary is associated with. */
  factory: Factory;
  /** The workstation this summary is for. Returns `null` if this summary is for a factory. */
  workstation?: Maybe<Scalars['ID']>;
  /** The year part of the year/week this summary is for. */
  year: Scalars['Int'];
  /** The week part of the year/week this summary is for. */
  week: Scalars['Int'];
  /** The aggregate values. The list contains one object per value type the user is allowed to view. */
  values: Array<CapacityOrderSummaryValues>;
  /** All the orders used in this summary. */
  orders?: Maybe<CapacityOrderSummaryOrdersConnection>;
};

/** Provides aggregate order capacity data for a factory or workstation. */
export type CapacityOrderSummaryOrdersArgs = {
  filters?: Maybe<CapacityOrderSummaryOrdersFiltersInput>;
  sort?: Maybe<Array<OrdersSortTermInput>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};

export enum OrderDocumentPrintType {
  SummaryChecklist = 'SUMMARY_CHECKLIST',
  QuoteFinal = 'QUOTE_FINAL',
  QuoteControl = 'QUOTE_CONTROL',
  OrderFinal = 'ORDER_FINAL',
  OrderControl = 'ORDER_CONTROL',
  ProductionAppendix = 'PRODUCTION_APPENDIX',
  ScheduleOfQuantities = 'SCHEDULE_OF_QUANTITIES',
  PalletList = 'PALLET_LIST',
  ExportExcel = 'EXPORT_EXCEL',
  ExportCsv = 'EXPORT_CSV',
  ExportXml = 'EXPORT_XML',
  Energy = 'ENERGY',
  EnergyXls = 'ENERGY_XLS',
  WebOrderPrintout = 'WEB_ORDER_PRINTOUT',
  Fdv = 'FDV',
  Epd = 'EPD',
  Epdxls = 'EPDXLS'
}

export type FactoryWeeklyValue = {
  __typename?: 'FactoryWeeklyValue';
  year: Scalars['Int'];
  week: Scalars['Int'];
  value: Scalars['Float'];
};

export type CapacityUpdatedPayload = {
  __typename?: 'CapacityUpdatedPayload';
  /** The combinations of workstations/years/weeks that were updated. */
  updated: Array<CapacityUpdatedPointer>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  orderNumber: Scalars['String'];
  orderType: OrderType;
  isFavorite: Scalars['Boolean'];
  price: OrderPriceData;
  salesPerson?: Maybe<User>;
  /** Returns the order's customer. For quotes, this returns the first item in `quoteCustomers`. */
  customer?: Maybe<Customer>;
  /**
   * Returns all customers for a quote. Returns `null` for non-quote orders. This is much more performant than going
   * via `frontPages`, and can safely be included in a list of orders. However, it only includes customers
   * in NorDan's customer database, not ad-hoc customers used only for this quote.
   */
  quoteCustomers?: Maybe<Array<Maybe<Customer>>>;
  customerOwner?: Maybe<Customer>;
  customerReference?: Maybe<Scalars['String']>;
  customerProject?: Maybe<Scalars['String']>;
  customerOrder?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  /** Returns the order's documents. This is an expensive field; do not load it in a list of orders. */
  documents: Array<OrderDocument>;
  /** Returns the order's front pages. This is an expensive field; do not load it in a list of orders. */
  frontPages: Array<OrderFrontPage>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type OrderDocumentsArgs = {
  filters: OrderDocumentsFiltersInput;
};

/** An edge in a connection. */
export type OrdersEdge = {
  __typename?: 'OrdersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

export type TestTriggerOrderUpdatedPayload = {
  __typename?: 'TestTriggerOrderUpdatedPayload';
  query: Query;
};

export type Error = {
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type CapacityOrderSummaryOrdersConnection = {
  __typename?: 'CapacityOrderSummaryOrdersConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<CapacityOrderSummaryOrdersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
};

export type CreateFavoriteInput = {
  itemType: FavoriteItemType;
  itemId: Scalars['ID'];
  index?: Scalars['Int'];
};

export type UserOrderAccess = {
  __typename?: 'UserOrderAccess';
  /** The order types the user has read access to. */
  readOrderTypes: Array<OrderType>;
  /** The order types for which the user has write access to non-test orders. */
  writeOrderTypes: Array<OrderType>;
  /** The order types for which the user has write access to test orders. */
  writeTestOrderTypes: Array<OrderType>;
};

export type CreateFavoriteError =
  | FavoriteItemNotFoundError
  | NoAccessToFavoriteItemError
  | ItemAlreadyFavoriteError;

export type CreateFavoritePayload = {
  __typename?: 'CreateFavoritePayload';
  favorite?: Maybe<Favorite>;
  errors?: Maybe<Array<CreateFavoriteError>>;
  query: Query;
};

export enum OrderType {
  Order = 'ORDER',
  Quote = 'QUOTE',
  StockDelivery = 'STOCK_DELIVERY',
  Credit = 'CREDIT',
  CustomerStock = 'CUSTOMER_STOCK'
}

export type OrderDocument = {
  __typename?: 'OrderDocument';
  id: Scalars['ID'];
  type: OrderDocumentType;
  printType?: Maybe<OrderDocumentPrintType>;
  category?: Maybe<Scalars['String']>;
  /** The document's extension. Lower-case and without the leading dot, e.g. `pdf` or `jpg`. */
  extension?: Maybe<Scalars['String']>;
  numBytes?: Maybe<Scalars['Long']>;
  /** A two-letter language code identifying the document's language. */
  language?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  owner?: Maybe<OrderDocumentOwner>;
  frontPage?: Maybe<OrderFrontPage>;
  orderType: OrderType;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<User>;
  downloadUrl: Scalars['String'];
};

/** Specifies the order of values in a sort operation. */
export enum SortOrder {
  /** Values are sorted in ascending order (from lowest to highest). */
  Asc = 'ASC',
  /** Values are sorted in descending order (from highest to lowest). */
  Desc = 'DESC'
}

export type CustomersFiltersInput = {
  /** Only return customers matching this search term. */
  searchTerm?: Maybe<Scalars['String']>;
};

/** Indicates that the item is already marked as a favorite by the user. */
export type ItemAlreadyFavoriteError = Error & {
  __typename?: 'ItemAlreadyFavoriteError';
  message: Scalars['String'];
};

/** Indicates that no favorite was found with the specified ID. */
export type FavoriteNotFoundError = Error & {
  __typename?: 'FavoriteNotFoundError';
  message: Scalars['String'];
};

export type SetFavoriteIndexInput = {
  favoriteId: Scalars['ID'];
  index: Scalars['Int'];
};

/** An edge in a connection. */
export type CapacityOrderSummaryOrdersEdge = {
  __typename?: 'CapacityOrderSummaryOrdersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

/** A connection to a list of items. */
export type OrdersConnection = {
  __typename?: 'OrdersConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<OrdersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export type WorkstationWeeklyCapacity = {
  __typename?: 'WorkstationWeeklyCapacity';
  /** The year part of the year/week these data are for. */
  year: Scalars['Int'];
  /** The week part of the year/week these data are for. */
  week: Scalars['Int'];
  /** The capacity status for the associated workstation in this week. */
  status: WorkstationCapacityStatus;
  /**
   * The usage in percent for the associated workstation in this week. Note that this could be outside the interval
   * [0, 100]. Returns `null` if there is no value (for example if the factory is closed).
   */
  percentageUsed?: Maybe<Scalars['Float']>;
};

export type TestTriggerCapacityUpdatedPayload = {
  __typename?: 'TestTriggerCapacityUpdatedPayload';
  query: Query;
};

export type OrdersSortTermInput = {
  order?: SortOrder;
  nulls?: Maybe<NullOrder>;
  key: OrdersSortKey;
};

export type Favorite = {
  __typename?: 'Favorite';
  id: Scalars['ID'];
  /** The index of this favorite among its siblings. */
  index: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /**
   * The item that is marked as favorite. This can be null, for example if the item no longer exists or the user no
   * longer has access to it.
   */
  item?: Maybe<FavoriteItem>;
};

export enum CapacityOrderType {
  Incomplete = 'INCOMPLETE',
  Control = 'CONTROL',
  Final = 'FINAL'
}

/** Indicates that no item with the specified ID was found to favorite. */
export type FavoriteItemNotFoundError = Error & {
  __typename?: 'FavoriteItemNotFoundError';
  message: Scalars['String'];
};

export type OrderFrontPage = {
  __typename?: 'OrderFrontPage';
  id: Scalars['ID'];
  customer?: Maybe<Customer>;
};

export type Customer = {
  __typename?: 'Customer';
  id?: Maybe<Scalars['ID']>;
  customerNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type OrderDocumentsFiltersInput = {
  /** Whether to return only the most relevant documents. */
  currentDocuments?: Scalars['Boolean'];
  /** Whether to include linked documents. */
  includeLinked?: Scalars['Boolean'];
  /** Whether to include agreement documents. */
  includeAgreement?: Scalars['Boolean'];
};

export enum PriceModel {
  Project = 'PROJECT',
  FrameworkAgreement = 'FRAMEWORK_AGREEMENT'
}

export enum CapacityCategory {
  Total = 'TOTAL',
  Cat1 = 'CAT1',
  Cat2 = 'CAT2',
  Cat3 = 'CAT3',
  Cat4 = 'CAT4'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};
